/* eslint-disable no-undef */
let countryData;
let selectedCountry;
let selectedMetric;

const chartDiv = document.getElementById('map');
const svg = d3.select(chartDiv).append('svg');
const map = svg.append('g');
const defs = svg.append('svg:defs');
defs
  .append('svg:pattern')
  .attr('id', 'ratingYield')
  .attr('width', 10)
  .attr('height', 10)
  .attr('patternUnits', 'userSpaceOnUse')
  .append('svg:image')
  .attr(
    'xlink:href',
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzEnLz4KPC9zdmc+Cg==',
  )
  .attr('width', 10)
  .attr('height', 10)
  .attr('x', 0)
  .attr('y', 0);
defs
  .append('svg:mask')
  .attr('id', 'ratingYieldMask')
  .attr('width', 1)
  .attr('height', 1)
  .attr('x', 0)
  .attr('y', 0)
  .append('rect')
  .attr('x', 0)
  .attr('y', 0)
  .attr('width', 10000)
  .attr('height', 10000)
  .attr('fill', 'url(#ratingYield)');

const urlParams = new URLSearchParams(window.location.search);
const urlDate = urlParams.get('date') || dateToString(new Date());

let paths;

const request = obj =>
  d3
    .request(url)
    .header('Authorization', credentials)
    .header('Content-Type', 'application/json')
    .response(xhr => JSON.parse(xhr.responseText).data[obj]);

const transformData = (data, allYieldsByRating) => {
  const result = [];

  data.forEach(node => {
    const obj = {
      bondYield: {
        regional_yield: null,
        yield: null,
      },
      iso2: node.iso2,
      iso3: node.iso3,
      name: node.countryTranslationsByIso2[0].translation,
      rating: {
        emerging_markets_volatility_adjustment: null,
        mature_market_erp: null,
        rating_credit_spread: null,
        mrp: null,
        rating: null,
        regional_mrp: null,
      },
      region: {
        name: node.countryRegionByCountry.regionByRegion.region,
        countries: [],
      },
      taxRate: {
        regional_tax_rate: null,
        tax_rate: null,
      },
    };

    const regionalValues =
      node.countryRegionByCountry.regionByRegion.regionalValuesByRegion[0];

    if (node.bondYieldsByCountry.length > 0) {
      obj.bondYield.yield = parseFloat(node.bondYieldsByCountry[0].yield);
    }

    if (node.ratingsByCountry.length > 0) {
      obj.rating.rating = node.ratingsByCountry[0].rating;
      obj.rating.mrp = parseFloat(node.ratingsByCountry[0].mrp);
      obj.rating.emerging_markets_volatility_adjustment =
        node.ratingsByCountry[0].emergingMarketsVolatilityAdjustment;
      obj.rating.mature_market_erp = node.ratingsByCountry[0].matureMarketErp;
      obj.rating.rating_credit_spread =
        node.ratingsByCountry[0].ratingCreditSpread;
    }

    if (node.taxRatesByCountry.length > 0) {
      obj.taxRate.tax_rate = node.taxRatesByCountry[0].taxRate;
    }

    if (regionalValues !== undefined) {
      if (regionalValues.regionalBondYields.length > 0)
        obj.bondYield.regional_yield =
          regionalValues.regionalBondYields[0].yield;
      obj.rating.regional_mrp = regionalValues.mrp;
      obj.taxRate.regional_tax_rate = regionalValues.taxRate;
    }

    if (numericRatingScale[obj.rating.rating] !== undefined) {
      obj.bondYield.based_on_rating =
        allYieldsByRating[numericRatingScale[obj.rating.rating] - 1]
          .yieldBasedOnRating / 100;
    }

    result.push(obj);
  });

  return result;
};

const dataLoaded = (error, geography, newCountryData, allRatingYieldsData) => {
  if (error) {
    throw error;
  }

  countryData = transformData(newCountryData, allRatingYieldsData);
  countryData = countryData.filter(el => el != null);

  drawMap(geography);

  const urlIso3 = urlParams.get('iso3');
  let urlCountryName = urlParams.get('name');

  switch (urlCountryName) {
    case 'United States':
      urlCountryName = 'United States of America';
      break;
    default:
      break;
  }

  let country = null;
  if (urlCountryName)
    country = countryData.find(c => c.name === urlCountryName);
  if (urlIso3) country = urlIso3 && countryData.find(c => c.iso3 === urlIso3);

  if (country) {
    changeCountry(country);

    // Select the country
    paths
      .filter(d => d.id === country.iso3)
      .each(d => {
        addSelectedCountryStyle(d, 0);
      });
  } else {
    showWorldData();
  }

  const defaultUrlMetric = 'riskFreeRate';
  let urlMetric = urlParams.get('metric');
  if (urlMetric) {
    urlMetric =
      metrics.find(metric => metric === urlMetric) || defaultUrlMetric;
  } else {
    urlMetric = defaultUrlMetric;
  }

  changeMetric(urlMetric);

  hideLoading();
};

const launchQuery = _urlDate => {
  const query = buildQueryWithDate(_urlDate, I18n.locale);
  const allYieldsQuery = buildAllYieldsByRatingQuery(_urlDate);

  queue()
    .defer(d3.json, 'world_countries.json')
    .defer(
      request('countries').post,
      JSON.stringify({
        query,
      }),
    )
    .defer(
      request('allYieldsByRating').post,
      JSON.stringify({
        query: allYieldsQuery,
      }),
    )
    .await(dataLoaded);
};

const updateCountryData = (error, newCountryData, allRatingYieldsData) => {
  if (error) {
    throw error;
  }

  countryData = transformData(newCountryData, allRatingYieldsData);
  countryData = countryData.filter(el => el != null);

  const country =
    selectedCountry && countryData.find(c => c.iso3 === selectedCountry.iso3);

  if (country) {
    changeCountry(country);
  } else {
    showWorldData();
  }
  changeMetric(selectedMetric);
  hideLoading();
};

const launchUpdateQuery = _urlDate => {
  const query = buildQueryWithDate(_urlDate, I18n.locale);
  const allYieldsQuery = buildAllYieldsByRatingQuery(_urlDate);

  queue()
    .defer(
      request('countries').post,
      JSON.stringify({
        query,
      }),
    )
    .defer(
      request('allYieldsByRating').post,
      JSON.stringify({
        query: allYieldsQuery,
      }),
    )
    .await(updateCountryData);
};

svg.on('click', () => {
  removeSelectedCountryStyle(map);
  showWorldData();
  selectedCountry = null;

  displayInfos();
});

launchQuery(urlDate);

window.addEventListener('message', e => {
  if (e.isTrusted) {
    // Reload data with new date
    showLoading();
    launchUpdateQuery(e.data);
  }
});
